
const config = {
  VERSION:  "0.0.2",
  MODE: process.env.REACT_APP_MODE ? process.env.REACT_APP_MODE : "local",
  CLIENT_ID: "U1VXT6ILURGElBAoTie7Lgij",
  CLIENT_SECRET: "4VNQacuFCOcSOmfWN7fXRPkCmif97HhtfoFKguqAwXzXaxBa",
  MAIL_PATTERN : /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/,

  BASE_PATH:     process.env.REACT_APP_MODE === "production" ? 'https://album.tqconnect.co.jp' :  process.env.REACT_APP_MODE === "development"  ?  'https://tqphotodev.tqconnect.co.jp' :  process.env.REACT_APP_MODE === "mock"  ?  'http://localhost:9000' : 'http://localhost:9000',

  USER_TYPE_finance :"経理",
  USER_FINANCE :"finance",
  USER_TYPE_manager: "マネージャー",

  EDIT:"更新",
  REGIST:"登録",

  SEARCH_LIMIT:10,
  START_SLIDESHOW_DURATION:1000 * 60,
  END_SLIDESHOW_DURATION:60000 * 5,


  API_PATH:                 process.env.REACT_APP_MODE === "production" ? 'https://photoapi.tqconnect.co.jp' :  process.env.REACT_APP_MODE === "development"  ?  'https://tqphotodevapi.tqconnect.co.jp':  process.env.REACT_APP_MODE === "mock"  ?  '/mock' : 'http://localhost:9000',
  API_AUTH:                 process.env.REACT_APP_MODE === "mock"  ? "/auth.json" : "/oauth/auth",
  API_PHOTO:                process.env.REACT_APP_MODE === "mock"  ? "/photo.json" : "/photos",
  API_PHOTO_DETAIL:         process.env.REACT_APP_MODE === "mock"  ? "/photo1.json" : "/photos",
  CLOSE_URL:                process.env.REACT_APP_MODE === "production" ? "https://album.tqconnect.co.jp/close" : "https://photodev.tqconnect.co.jp/close"
}   


export default config;
