import React,{ useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import usePhotoState from './usePhotoState';
export const PhotoDetail = ({
  token,
  photo,
  mode,
  notice,
  clickHandler,
  deleteHandler,
  readHandler
}) => {
  const [message, setMessage] = useState(null)
  const [comment, setComment] = useState(photo.comment)

  const [img, setImage] = useState()
  const {media, getMedia} = usePhotoState()
  
  useEffect(() => {
    if(media)
      setImage(window.URL.createObjectURL(media))
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[media]);

  useEffect(() => {
    if(token)
      getMedia(token, photo.url)
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);



  useEffect(() => {

    if(notice)
      setMessage(notice)
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[notice]);

  
  const displayComment = () => {
    setComment(photo.photo ? photo.photo : " ")
  }

  const commenChangeHandler =(e) => {
    const target = e.target 
    setComment(target.value)
  }

  const btnClickHandler = () => {
    if(comment.length > 30){
      setMessage("30文字以上は送信できません")
    }else{
      setMessage(null)
      clickHandler(photo.id, comment)
    }
    
  }

  const readClickHandler = () => {
    if(readHandler){
      readHandler()
    }
  }

  return(
    <div className="photo-detail" >
      
      <div className="photo" onClick={readClickHandler}>
        <div className="image"><img src={img} alt="写真" /></div>
        <div className={"read " + mode}>{photo.read_at ? <i className="fa-solid fa-heart"></i> : <i className="fa-regular fa-heart"></i>}</div>
      </div>
     
     {clickHandler && comment ? <div className="comment"><textarea value={comment} onChange={commenChangeHandler}/> </div>: clickHandler ? <div className="btn" onClick={displayComment}>コメントを入力(30文字以内)</div> : null } 
     
     
     {clickHandler && photo.comment !== comment ? <div className="btn" onClick={() => btnClickHandler()}>送信</div> : null } 
     <div className="message red big">{message}</div>
     {deleteHandler ? <div className="btn" onClick={() => deleteHandler(photo.id)}>この写真を削除</div> :null}
    
    </div>
);}

PhotoDetail.propTypes = {
  token:PropTypes.string,
  photo:PropTypes.object,
  notice:PropTypes.string,
  clickHandler: PropTypes.func,
  deleteHandler: PropTypes.func
};

PhotoDetail.defaultProps = {
  token:"",
  photo:{},
  notice:null,
  clickHandler:()=>{},
  deleteHandler:()=>{}
};
