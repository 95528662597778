import React,{ useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import usePhotoState from './usePhotoState';
export const PhotoItem = ({
  token,
  photo,
  mode,
  index,
  clickHandler
}) => {



  const [img, setImage] = useState()
  const {media, getMedia} = usePhotoState()
  
  useEffect(() => {
    if(media){

      setImage(window.URL.createObjectURL(media))
    }
      
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[media]);

  useEffect(() => {

    getMedia(token, photo.url)
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[photo]);


  const itemClickHandler = () => {
    clickHandler(photo.id)
  }
  

  return(
    <div className={"photo-item " + mode }onClick={() => itemClickHandler()}>
     { img ? <img src = {img} alt="写真" /> : null}
      {mode === "delete" ? <div className="deleteIcon"><i className="fa-solid fa-circle-check"></i></div> : null}
      { mode === "list" || mode === "mobile-list" || mode === "detaile" ||  mode === "slide" ? <div className={"read " + mode}>{photo.read_at ? <i className="fa-solid fa-heart"></i> : <i className="fa-regular fa-heart"></i>}</div> : null}
    
    </div>
);}

PhotoItem.propTypes = {
  token:PropTypes.string,
  photo:PropTypes.object,
  index:PropTypes.number,
  mode:PropTypes.string,
  clickHandler: PropTypes.func
};

PhotoItem.defaultProps = {
  token:"",
  index:10000,
  photo:{},
  mode:"list",
  clickHandler:()=>{}
};
