import React, {useEffect,useState  } from 'react';
import { useParams } from 'react-router-dom';
import { PhotoDetail } from './PhotoDetail';
import usePhotoState from './usePhotoState';
import { Footer } from '../footer/Footer';
import { DeleteWindow } from '../stories/delete_window/DeleteWindow';
import useAuthState from '../stories/auth/useAuthState';
function PhotoDetailMobApp(props:PropsValue) {
  const {id} = useParams()
  const [item, setItem] = useState(null)
  const [message, setMessage] = useState(null)
  const [delete_item, setDeleteItem] = useState(null)
  const [delete_window, setDeleteWindow] = useState(null)
  const {photo_item, updated_at, deleted_at, getPhoto, commentUpdate ,deletePhoto} = usePhotoState()
  const {token, getToken} = useAuthState()
  useEffect(() => {
     getToken()
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if(token){
      getPhoto(token, id)
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token]);

  useEffect(() => {
    if(updated_at){
      setMessage("コメントを更新しました。")
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[updated_at]);

  useEffect(() => {
    if(deleted_at){
      window.location.href ="/mob/"
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[deleted_at]);

  useEffect(() => {
    if(delete_item){
      setDeleteWindow(<DeleteWindow message={"この写真を削除してよろしいですか。"} closeHandler={deletCloseHandler}  executeHandler={deleteExe}/>)
    }else{
      setDeleteWindow(null)
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[delete_item]);
  
  useEffect(() => {
    if(photo_item){
      setItem(photo_item)
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[photo_item]);

  const commentUpdateHadler = (id, comment) => {
    commentUpdate(token,  id, comment)
  }

  const deleteExe = () => {
    console.log("id")
    setDeleteItem(null)
    
    deletePhoto(token, id)
  }
  const deletCloseHandler = () => {
    setDeleteItem(null)
  }
  const deleteHandler = (id, comment) => {
   // deletePhoto()
   setDeleteItem(id)
   //window.location.href ="/mob/"
  }

  return(
    <div className="mob photo-detail">
      {item ? <PhotoDetail mode="detaile-mob" photo={photo_item} token={token} notice={message} clickHandler={commentUpdateHadler} deleteHandler={deleteHandler} /> : null}
      {delete_window}
      <Footer selected="item" />
    </div>
  )
}

export default PhotoDetailMobApp
