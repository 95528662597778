import React, {useEffect,useState  } from 'react';
import { useParams } from 'react-router-dom';
import config from 'react-global-configuration';
import { PhotoDetail } from './PhotoDetail';
import usePhotoState from './usePhotoState';
import useAuthState from '../stories/auth/useAuthState';
import btn_r from "../images/a_r.png"
import btn_l from "../images/a_l.png"

function PhotoDetailMobApp(props:PropsValue) {
 
  const {id} = useParams()
  const [item, setItem] = useState(null)
  const [comment, setComment] = useState(null)
  const {photo_item, photo_list, getPhoto, getPhotoList, read} = usePhotoState()
  const {token, getToken} = useAuthState()

  useEffect(() => {
    getToken()
    setTimeout(moveToSlidShow,config.get("START_SLIDESHOW_DURATION"))
    // eslint-disable-next-line react-hooks/exhaustive-deps
 },[]);


  useEffect(() => {
    if(token){
      getPhoto(token, id)
      getPhotoList(token)
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[token]);


  useEffect(() => {
    if(photo_item){
      setItem(photo_item)
      if(photo_item.comment){
        if(photo_item.comment.length >= 15){
          setComment(
            <div className="text-row-multi">
              <div className="tate-inner">{photo_item.comment.substring(0,15)}</div><div className="tate-inner">{photo_item.comment.substring(15,30)}</div>
            </div>
          )
        }else{
          setComment(
            <div className="text-row-multi">
              <div className="tate-inner">{photo_item.comment}</div>
            </div>
          )
        }
      }
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[photo_item]);

  
  const moveTo = (url) => {
    window.location.href = url
  }

  const goPrev = () => {
    const my_position = findIndex()
    if(my_position <= 0) return null 
    else{
      updateRead()
      window.location.href = "/tab/detail/" + photo_list[my_position - 1].id
    }
  }

  const goNext = () => {
    const my_position = findIndex()
    if(my_position >= photo_list.length -1) return null 
    else{
      updateRead()
      window.location.href = "/tab/detail/" + photo_list[my_position + 1].id
    }
  }



  const findIndex = () => {
    if(photo_list) {
       return photo_list.findIndex(item => item.id === id)
    }else{
      return -1
    }
  }

  const readHandler = () => {
      updateRead()
  }


  const updateRead = () => {
    if(photo_item && !photo_item.read_at){
      read(token, id)
    }
    
  }

  const moveToSlidShow = () => {
    window.location.href = "/tab/slideshow?path=/tab/detail/&id=" + id
  }


  return(
    <div className="tab photo-detail-wrap">
      <div className="main">
        
        {item && token ? <PhotoDetail token={token} photo={photo_item} notice={null} mode={"detaile"} clickHandler={null} deleteHandler={null}  readHandler={readHandler}/> : null}
        <div className="left_btn" onClick={goPrev}><img src={btn_l} alt="矢印"/></div>
        <div className="right_btn" onClick={goNext}><img src={btn_r} alt="矢印"/></div>
      </div>
    <div className="btns">
      <div className="btns-item" onClick={()=>moveTo("/tab/")}><div className="label">一覧</div></div>
      <div className="comment-tategaki" >{comment}</div>
    </div>
  </div>
  )
}

export default PhotoDetailMobApp
