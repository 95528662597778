import React, { useEffect, useState  } from 'react';
//import {  useSearchParams } from 'react-router-dom';
import config from 'react-global-configuration';
import usePhotoState from './usePhotoState';

import useAuthState from '../stories/auth/useAuthState';
import { PhotoItem } from './PhotoItem';
function PhotoSlideShowApp(props:PropsValue) {
  
  const duration = 10000
  const duration_f = 60000 * 1
//  const stopintg_duration = 60000 * 1
  
  const [item, setItem] = useState(null)
 // const [searchParams] = useSearchParams();

 // const path = searchParams.get("path") ?  searchParams.get("path") : "/tab/" ;
 // const back_id = searchParams.get("id") ?  searchParams.get("id") : null ;
  const [image_class, setImageClass] = useState(null)
  const [curren_id, setCurrentId] = useState(-1)
  const [slide_list, setSlidLiest] = useState([])
  const [comment, setComment] = useState(null)
  const {updated_at, photo_list, list_updated_at, getPhotoList, read} = usePhotoState()
  const {token, getToken} = useAuthState()
  const [counter, setCounter] = useState()

  //const [progress_id, setProgressId] = useState(null)
  //const [back_timeout_id, setBackTimeoutId] = useState(null)
  useEffect(() => {
   
    if(!checkWorkingTime()){
      moveToHisotry()
    }else{
      getToken()
      //setBackTimeoutId()
      //setTimeout(moveToHisotry,config.get("END_SLIDESHOW_DURATION"))
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);



    useEffect(() => {
      if(token){
        getPhotoList(token)
      }
      
       // eslint-disable-next-line react-hooks/exhaustive-deps
    },[token]);

  useEffect(() => {
   
    if(curren_id >= 0){
      startImage()
    }
  
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[curren_id]);

  useEffect(() => {
    if(slide_list && slide_list.length > 0){
      setCurrentId(0)
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[slide_list]);

  useEffect(() => {
    if(updated_at){
      //console.log(updated_at)
     moveToHisotry()
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[updated_at]);

  
  const moveToHisotry = () => {
    /**const return_path = back_id ? path + back_id : path
    console.log(return_path)
    console.log(back_timeout_id)
    window.close(); **/

    window.location.href = config.get("CLOSE_URL")
    //setMessage(config.get("CLOSE_URL"))
  }

  const checkWorkingTime = () => {
    const today = new Date()
    const h = today.getHours()
    return (h >= 10 && h < 20)
  }

  const startImage = () => {
    const photo = slide_list[curren_id]
      if(photo){
        setItem(<PhotoItem token={token} photo={photo} mode="slide"/>)
        setImageClass("show")

        if(photo.comment){
          if(photo.comment.length >= 15){
            setComment(
              <div className="text-row-multi">
                <div className="tate-inner">{photo.comment.substring(0,15)}</div><div className="tate-inner">{photo.comment.substring(15,30)}</div>
              </div>
            )
          }else{
            setComment(
              <div className="text-row-multi">
                <div className="tate-inner">{photo.comment}</div>
              </div>
            )
          }
        }else{
          setComment(null)
        }
      }
      const du = curren_id === 0 ? duration_f : duration
      setTimeout(hideImage,du)
  }
  const hideImage = () => {
    setImageClass("hide")
    setTimeout(changePhoto,300)
  }
  const changePhoto = () => {
    if(checkWorkingTime()){
      if(curren_id + 1 < slide_list.length ){
        setCurrentId(curren_id + 1)
      }else{
        setCurrentId(0)
      }
      setCounter(counter + 1)
    }else{
      moveToHisotry()
    }
   
  }

  /**const restartHandler = () => {
    clearTimeout(back_timeout_id)
    setBackTimeoutId(setTimeout(moveToHisotry,config.get("END_SLIDESHOW_DURATION")))
    startImage()
  }**/


  const clickHandler = () => {
    
    const photo = slide_list[curren_id]
    if(photo && !photo.read_at){
      read(token, photo.id)
    }else{
      moveToHisotry()
    }

    //moveToHisotry()
    /**
    if(progress_id){
      clearTimeout(progress_id)
      setProgressId(null)
      setBackTimeoutId(setTimeout(restartHandler,stopintg_duration))
    }else{
      restartHandler()
    }**/


  }

  useEffect(() => {
    if(photo_list){
     setSlidLiest(photo_list)
    }
    
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[list_updated_at]);



  return(
    
   
    <div className="tab photo-slide" onClick={clickHandler}>
     
    <div className={"slide-show-item " + image_class}>
        {item}
        {comment ? <div className="comment-tategaki white" ><div className="back"></div>{comment}</div> : null}
       </div> 
    </div>
   
  )
}

export default PhotoSlideShowApp


/**
 *  
 */