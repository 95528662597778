
import 'whatwg-fetch'

import config from 'react-global-configuration';
import Action from '../action/Action';

const PhotoAction = {  

  getList( token, handler, errorHandler ){
    const path = config.get("API_PATH") + config.get("API_PHOTO")
    Action.fetchAuthorizedJson(path,token, "GET", {}, null,  handler, errorHandler)
  },


  get( token, id,  handler, errorHandler ){
    const path = config.get("API_PATH") + config.get("API_PHOTO_DETAIL") + "/" + id
    Action.fetchAuthorizedJson(path, token, "GET", {}, null,  handler, errorHandler)
  },

  getMedia( token, url,  handler, errorHandler ){
    
      Action.getMedia(url, token, handler, errorHandler)
  
  },



  createMedia( token, file, room_id, handler, errorHandler ){
    const path = config.get("API_PATH") + config.get("API_PHOTO_DETAIL") + "/create/" + room_id
    const formData = new FormData();
    formData.append('file', file);
  
    Action.createMedia(path, token, formData, handler, errorHandler,{token:token})
  },

  update( token, id, comment, handler, errorHandler ){
    const path = config.get("API_PATH") + config.get("API_PHOTO_DETAIL")  + "/" + id
    const header = {'Content-Type': 'application/json'}
    const item = {comment:comment}
    Action.fetchAuthorizedJson(path, token, "PATCH", header,  JSON.stringify(item),  handler, errorHandler)
  },

  read( token, id, handler, errorHandler ){
    const path = config.get("API_PATH") + config.get("API_PHOTO_DETAIL")  + "/" + id + "/read"
    const header = {'Content-Type': 'application/json'}
    const item = {}
    Action.fetchAuthorizedJson(path, token, "PATCH", header,  JSON.stringify(item),  handler, errorHandler)
  },

  delete( token, id, handler, errorHandler ){
    const path = config.get("API_PATH") + config.get("API_PHOTO_DETAIL")  + "/" + id
    Action.fetchAuthorizedDelete(path, token, {},  handler, errorHandler)
  },

  //cookie.save('current_cart_id', data.id, { path: '/' })
  
}

export default PhotoAction
